import Swal from 'sweetalert2'

const StartLoading = ({ commit, state }) => {
	commit('ADD')
}

const FinishLoading = ({ commit, state }) => {
	commit('REM')
}

const GetSections = ({ commit, state }) => {

	return window.api.call('get', '/get-sections')
		.then(({data}) => {
			if(data.response)
			{
				commit('UPDATE_SECTIONS', data.sections)
				commit('UPDATE_CATEGORIES', data.categories)
			}
		})
}

export {
	StartLoading,
	FinishLoading,
	GetSections
}