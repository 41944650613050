const ADD 				= (state) => {
	state.request_loading++
}

const REM 				= (state) => {
	state.request_loading--
}

const UPDATE_CATEGORIES = (state, payload) => {
	state.categories = payload
}

const UPDATE_SECTIONS 	= (state, payload) => {
	state.sections = payload
}

export {
	ADD,
	REM,
	UPDATE_CATEGORIES,
	UPDATE_SECTIONS
}