import Vue from 'vue'
import Vuex from 'vuex'

import auth from './store/auth'
import system from './store/system'

Vue.use(Vuex)

export default function(){
    const store = new Vuex.Store({
        modules: {
            auth,
            system
        }
    })

    return store
}