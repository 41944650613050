<template>
	<div class="col-12 px-0">
		<header 
			class="col-12 px-3 py-3 d-flex align-items-center justify-content-center">
   			<div class="col-12 col-xl-8 px-0 d-flex justify-content-between align-items-center gap-3">
        		<div id="instant_logo" class="col-auto px-0">
					<router-link
						:to="{ name: 'Home' }">
						<img :src="logo_url" width="150">
					</router-link>
				</div>
				<div v-if="!is_home"
					class="col p-0 d-flex flex-column align-items-center">
					<input
						v-debounce:500="SearchDeep"
						type="text"
						class="col-12 form-control font-18 search"
						:class="{ 'is-invalid': $v.search.$error }"
						v-model="$v.search.$model"
						placeholder="Digite palavras-chave para encotrar sua resposta"
						>
					<div class='invalid-feedback text-center search-feedback'>
						Deve ter no mínimo 3 caractéres.
					</div>
				</div>

		        <div class="col-auto px-0"
					v-if="!is_home">
					<router-link
						class="btn btn-block px-3 btn-light"
						:to="{ name: 'Home' }"
					>
						<i class="far fa-home mx-2"></i>
					</router-link>
				</div>

		        <div id="contact-button" class="col-auto px-0"
					@click="$emit('support')">
					<router-link
						class="btn btn-block px-3 btn-light"
						:class="is_home ? 'd-flex justify-content-center align-items-center' : ''"
						:to="{ name: 'Contact' }"
					>
						<i class="far fa-phone-alt mx-2"></i>
						<p v-if="is_home"
							class="m-0">CONTATO</p>
					</router-link>
				</div>
			</div>
		</header>
	</div>

</template>

<script>
	import { mapGetters, mapActions } from 'vuex'

	import { minLength } from 'vuelidate/lib/validators'

	import Swal from 'sweetalert2'

	const _ = require('lodash')
	export default {

		name: 		'Navbar',
		components: 	{
		},
		props: 			{
			carousel: 		{
				type: 			Boolean,
				default: 		false
			},
			is_home:		{
				type:			Boolean,
				default:		true
			}
		},
		data () {
			return {
				logo_url: require('../../../assets/img/logo_original.png'),
				search: ''
			}
		},
		validations: {
			search: {
				minLength: minLength(3)
			}
		},
		computed: {
		},
		watch: {
			search: {
				handler(val)
				{
					this.$emit('search', val)
				}
			},
			$route: {
				handler(val)
				{
					this.search = ""
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			async SearchDeep()
			{
				const vm = this
				if(vm.search.length == 0) return

				vm.$v.search.$touch()

				if(!vm.$v.search.$invalid)
				{
					vm.StartLoading()
	
					return await window.api.call('post', '/search-articles', {
						busca: vm.search
					})
						.then(({data}) => {
							if(data.response)
							{
								this.$emit('update', {
									articles: data.articles,
									quantity: data.quantidade
								})
								return
							}
						})
						.finally(() => {
							vm.FinishLoading()
						})
				}
        	}
		},
		mounted() {

		}
	}
</script>

<style lang="scss" scoped>
.gap-3 {
	gap: 16px;
}

.search-feedback
{
	position: absolute;
	top: 60px;
}

</style>