<template>
    <div class="pl-md-3">
        <div class="article-content mb-5"
            v-if="selected_article.titulo">
            <div class="header row d-flex justify-content-center">
                <h2 class="col-12 text-center">{{ selected_article.titulo }}</h2>
                <p class="mt-3 font-20" v-if="selected_article.updated_at">Atualização: {{ ParseLastUpdate(selected_article.updated_at) }}</p>
            </div>
            <div class="main mt-5">
                <div class="mb-3" v-html="selected_article.texto">
                </div>
            </div>
            <div class="footer row mt-3">
                <p class="col-12 p-0 m-0">Escrito por</p>
                <div class="footer-content d-flex align-items-center justify-content-center">
                    <img :src="avatar" class="footer-avatar mt-2 mr-3">
                    <div class="d-flex flex-column justify-content-center">
                        <b class="text-uppercase">
                            {{ selected_article.author.nome }}
                        </b>
                        <p class="mt-2 mb-0">
                            {{ selected_article.author.cargo }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const he = require('he')
import { mapActions } from 'vuex'

export default {
    name: "Article",
    props:      {
        value:        {
            type:       Object,
            default:    () => {}
        }
    },
    data() {
        return {
            selected_article:   {}
        }
    },
    computed: {
        avatar()
        {
            if(this.selected_article.author)
            {
                if(this.selected_article.author.avatar)
                {
                    return this.selected_article.author.avatar
                }
            }

            return require('../../../assets/img/profile.png')
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val)
            {
                this.selected_article = val
            }
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        ParseLastUpdate(date)
        {
            let new_date = date.substr(0, 10).split('-').reverse().join('/')
            let new_time = date.substr(11, 5)

            return `${new_date} às ${new_time}`
        }
    }
}
</script>

<style>

</style>