<template>
    <div class="row pb-5 justify-content-center">
        <div
            v-for="category in categories"
            :key="category.id"
            class="mb-4 px-1 px-md-3 mt-5 col-6 col-md-3 col-lg-2"
        >
            <router-link 
                class="box w-100 icons-card primary-hover"
                :to="{ name: 'Category', params: {
                        category_slug: category.slug,
                    },
                }"
            >
                <div class="card-body text-center position-absolute ps-0 d-flex align-items-center justify-content-center">
                    <div class="form-row h-100">
                        <div class="col-12 align-self-center">
                            <i :class="`fal fa-${category.icone} font-40 mt-3 text-theme`"></i>
                        </div>
                        <div class="col-12 align-self-start">
                            <h5 class="m-0 font-20">
                                {{ category.descricao }}
                            </h5>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
const _     = require('lodash')

export default {
    name: "Categories",
    props: {

    },
    data() {
        return {
            all_categories:         []
        }
    },
    watch: {

    },
    computed: {
        ...mapGetters('system', [
            'categories'
        ])
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ])
    },
    beforeMount()
    {
        // this.GetCategories()
    }
}
</script>

<style>

</style>