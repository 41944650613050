<template>
	<div class="vh-100 px-0 d-flex flex-column">
		<div class="px-0 mb-5">
			<Loading />

			<Navbar 
				:is_home="is_home" />
		</div>

		<transition name="fade" mode="out-in">
			<router-view 
			 	class="flex-grow-1"
				@update-search="UpdateSearch"
				:props_search="search" />
		</transition>

		<footer class="footer-container">
			<div class="footer-icons mt-2">
				<a href="">
					<i class="mx-2 fab fa-linkedin-in"></i>
				</a>
				<a href="">
					<i class="mx-2 fab fa-facebook"></i>
				</a>
				<a href="">
					<i class="mx-2 fab fa-instagram"></i>
				</a>
				<a href="">
					<i class="mx-2 fab fa-youtube"></i>
				</a>
			</div>
			<p class="footer-text">© Copyright 2021 Metodologia Instant. Todos os direitos reservados.
			</p>
		</footer>
	</div>
</template>

<script>
	import Navbar from './sections/Navbar'
	import Loading from './Loading'

	export default {

		name: 'Index',
		components: 	{
			Navbar,
			Loading
		},
		data () {
			return {
				search: ''
			}
		},
		computed: {
			is_home()
			{
				return this.$route.name == 'Home'
			}
		},
		watch: {
			search: {
				handler(val) {
					if(val == 0) {
						this.articles = []
					}
				}
			}
		},
		methods: {
			UpdateSearch(val) {
				this.search = val
			},
		},
        beforeMount()
        {
        }
	}
</script>

<style lang="css" scoped>
</style>