<template>
    <div>
        <Sections
            :props_search="props_search"
            :category_id="selected_category.id"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Sections from './../../sections/Sections'

export default {
    name: "Category",
    components: {
        Sections
    },
    props: {
        category_slug:      {
            type:       String,
            default:    ''
        },
        props_search:       {
            type:       String,
            default:    ''
        }
    },
    data () {
        return {
            category:   {
                id:     null
            }
        }
    },
    computed: {
        ...mapGetters('system', [
            'categories'
        ]),
        selected_category()
        {
            let category = this.categories.filter(c => c.slug == this.category_slug)[0]

            if(category)
            {
                return category
            }

            return {}
        }
    },
    watch: {
        selected_category: {
            deep: true,
            immediate: true,
            handler(val)
            {
                if(val.descricao)
                {
                    document.title = "IM | FAQ - " + val.descricao
                } else {
                    document.title = "IM | FAQ"
                }
            }
        }
    }
}
</script>

<style>

</style>