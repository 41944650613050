require('./bootstrap')

import Vue from 'vue'

import Api from './api'
window.api    = new Api()

import router from './routes'

import App from './Index'

import store from './vuex'

// window.Pusher   = require('pusher-js')

// import Echo from './echo'
// window.Echo   = new Echo()


const app = new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
