<template>
    <div class="container-fluid sections-container col-12 col-md-6"
        v-if="selected_category">
        <h2>{{ selected_category.descricao }}</h2>
        <div v-if="filtered_sections.length == 0" 
            class="mt-5 d-flex justify-content-center">
            <p>Não há seções disponíveis para esta categoria!</p>
        </div>
        <ul class="list-container">
            <li v-for="(section, index) in filtered_sections"
                :key="index"
                class="section">
                <router-link
                    :to="{ name: 'Section' , params: {
                        section_slug: section.slug,
                    }}">
                    {{ section.descricao }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: "Sections",
    props:  	{
        category_id:        {
            type:       [String, Number],
            default:    null
        },
        props_search:       {
            type:       String,
            default:    ''
        }
    },
    data () {
        return {
            category_name:          '',
            search:                 ''
        }
    },
    computed: {
        ...mapGetters('system', [
            'categories', 'sections'
        ]),
        all_sections()
        {
            return this.sections.filter(s => s.faq_category_id == this.category_id)
        },
        filtered_sections()
        {
            let filtered = this.all_sections.filter(s => {
                let parse_description = s.descricao.toLowerCase()
                return parse_description.indexOf(this.search) > -1
            })
            
            return filtered
        },
        selected_category()
        {
            return this.categories.filter(c => c.id == this.category_id)[0]
        }
    },
    watch: {
        props_search: {
            handler(val)
            {
                this.search = val
            }
        }
    },
    methods: {
        
    },
}
</script>

<style>

</style>