<template>
    <div class="d-flex justify-content-center">
        <div class="col-12 col-md-6 form-container">
            <h2 class="">CONTATO</h2>
            <p class="ml-2 font-18">Envie sua dúvida aqui, responderemos assim que possível!</p>
            <div class="content-form mt-5 mb-4 d-flex align-items-center">
                <div class="mx-3">
                    <p class="m-0">Entre em contato com nosso suporte pelo Whatsapp.</p>
                </div>
                <a 
                    class="btn btn-success whatsapp py-2 px-3 d-flex align-items-center"
                    href="https://api.whatsapp.com/send?phone=5511932992724&text=Ol%C3%A1!" 
                    target="_blank"
                >
                    Clique aqui
                    <i class="ml-2 fab fa-whatsapp font-24"></i>
                </a>
            </div>
            <hr class="m-0">
            <div class="content-form mt-4 mb-5 d-flex flex-column align-items-center justify-content-center">
                <div class="mx-3 align-self-start">
                    <p>Se preferir nos envie um e-mail.</p>
                </div>
                <div class="mx-3 my-3 col-12 col-md-10">
                    <label for="nome"
                        class="font-24 col-12">Nome</label>
                    <input 
                        type="text"
                        class="form-control col-12"
                        id="nome"
                        v-model="form.nome">
                </div>
                <div class="mx-3 my-3 col-12 col-md-10">
                    <label for="email"
                        class="font-24 col-12">Email</label>
                    <input 
                        type="text"
                        class="form-control col-12"
                        id="email"
                        v-model="form.email">
                </div>
                <div class="mx-3 my-3 col-12 col-md-10">
                    <label for="telefone"
                        class="font-24 col-12">Telefone</label>
                    <input 
                        type="text"
                        class="form-control col-12"
                        id="telefone"
                        v-model="form.telefone">
                </div>
                <div class="mx-3 my-3 col-12 col-md-10">
                    <label for="assunto"
                        class="font-24 col-12">Assunto</label>
                    <input 
                        type="text"
                        class="form-control col-12"
                        id="assunto"
                        v-model="form.assunto">
                </div>
                <div class="mx-3 my-3 col-12 col-md-10">
                    <label for="mensagem"
                        class="font-24 col-12">Mensagem</label>
                    <textarea 
                        type="text"
                        class="form-control col-12"
                        id="mensagem"
                        v-model="form.mensagem" />
                </div>
                <div class="mx-md-3 mt-4 col-12 d-flex justify-content-end pr-5">
                    <button class="btn btn-primary mr-md-5"
                        @click="SaveContact()">
                        Enviar
                        <i class="ml-2 far fa-paper-plane"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'

export default {
    name: "Contact",
    data() {
        return {
            form: {
                nome:       '',
                email:      '',
                telefone:      '',
                assunto:    '',
                mensagem:    ''
            }
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async SaveContact()
        {
            const vm = this

            vm.StartLoading()

            return await window.api.call('post', '/save-contact', {
                ...vm.form
            })
                .then(({data}) => {
                    if(data.response)
                    {
                        Swal.fire({
                            title:      'Mensagem enviada!',
                            icon:       'success',
                            toast:      true,
                            position:   'top',
                            timer:      3000,
                            timerProgressBar: true,
                            showConfirmButton: false,
                        })

                        vm.ClearForm()
                        return
                    } 

                    if(data.mensagem == 'The given data was invalid.')
                    {
                        Swal.fire({
                            title:       'Algum dado está incorreto',
                            icon:       'error',
                            toast:      true,
                            position:   'top',
                            timer:      3000,
                            timerProgressBar: true,
                            showConfirmButton: false,
                        })

                        return
                    }

                    Swal.fire({
                        title:      'Ops...',
                        text:       'Ocorreu um erro, tente novamente mais tarde!',
                        icon:       'error',
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        ClearForm()
        {
            this.form = {
                nome:       '',
                email:      '',
                telefone:      '',
                assunto:    '',
                mensagem:    ''
            }
        }
    }
}
</script>

<style lang="css" scoped>

.content-form textarea
{
    resize: none;
    height: 180px;
}

</style>