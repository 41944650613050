import Vuex from './vuex'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import Swal from 'sweetalert2'

NProgress.configure({
    showSpinner: false
});

const router = new VueRouter({
    mode:   'history',
    routes: [
		{
			path: '*',
			redirect: {
				name: 'Home'
			}
		},
        {
            path: '/',
			component: require('./components/layout/Index.vue').default,
			children: [
                {
					path: '/',
					component: require('./components/layout/EmptyParentComponent.vue').default,
					children: [
						{
							name: 'Home',
							path: 'inicio',
							component: require('./components/home/Index.vue').default,
							default: true,
							meta: {
								title:          'Início',
							}
						},
						{
							name: 'Contact',
							path: 'contato',
							component: require('./components/contact/Contact.vue').default,
							default: true,
							meta:	{
								title:	'Contato'
							}
						},
						{
							name: 'Category',
							path: ':category_slug',
							component: require('./components/categories/components/Category.vue').default,
							props: true,
							default: true,
							meta:	{
								title:	'Seções'
							}
						},
						{
							name: 'Section',
							path: ':category_slug/:section_slug',
							component: require('./components/sections/components/Section.vue').default,
							props: true,
							default: true,
							meta:	{
								title:	'Artigos'
							}
						},
						{
							name: 'Article',
							path: ':category_slug/:section_slug#:article_slug',
							component: require('./components/sections/components/Section.vue').default,
							props: true,
							default: true,
						},
						{
							path: '*',
							redirect: {
								name: 'Home'
							}
						}
                    ]
                },
				{
					path: '*',
					redirect: {
						name: 'Home'
					}
				},
            ]
        },
		{
			path: '',
			redirect: {
				name: 'Home'
			}
		},
    ]
});

router.beforeEach((to, from, next) => {
	NProgress.start()
    next();
})

router.afterEach((to) => {
	document.title = 'IM | FAQ - ' + to.meta.title

    NProgress.done()
})

export default router;