import Vue from 'vue'

window._            = require('lodash')

window.axios        = require('axios')

window.axios.defaults.headers.common['X-Requested-With']    = 'XMLHttpRequest';

window.axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api/faq`

const authToken = localStorage.getItem('token')

if (authToken)
{
    window.axios.defaults.headers.common['Authorization']       = 'Bearer ' + authToken
}

try {
    window.Popper   = require('popper.js');
    window.$        = window.jQuery = require('jquery');

    require('bootstrap');

} catch (e) {
    
    console.log('error: ' + e.toString())
}

window.Event        = new Vue

import VueDebounce from 'vue-debounce'
Vue.use(VueDebounce, {
    listenTo: 'input'
})

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import Helpers from './helpers'
window.helpers      = new Helpers()

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import VueDragscroll from 'vue-dragscroll'
Vue.use(VueDragscroll)

import money from 'v-money'
Vue.use(money, {
    precision:  2,
    prefix:     'R$ ',
    decimal:    ',',
    thousands:  '.'
})

import './assets/sass/styles.scss'

require('./global-components')
require('./global-variables')
require('./directives')