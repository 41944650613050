export default {
	request_loading:		0,
	categories:				[],
	sections:				[],
	menu_options: 			[
		{ id: 2, icon: 'fa-star', text: 'Novidades', route: 'News'},
		{ id: 3, icon: 'fa-list-ol', text: 'Tutoriais', route: 'Tutorials'},
		{ id: 1, icon: 'fa-question-circle', text: 'Perguntas Frequentes', route: 'FA'},
		{ id: 4, icon: 'fa-plus', text: 'Cadastro', route: 'Register'},
	]
}