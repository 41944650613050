<template>
	<div class="container-large mt-5 px-3">
		<Search 
			ref="search"
			v-if="is_home"
			@search="UpdateSearch"
			@update="UpdateArticles" />

		<div class="col-md-6 offset-md-3 mb-5 px-0"
			v-if="!is_home">
			<button class="px-5 btn-previous btn btn-light"
				@click="Previous">
				<i class="fal fa-chevron-left px-2"></i>
			</button>
		</div>
		
        <Categories />

		<transition name="fade" mode="out-in">
			<div class="col-12 col-md-8 my-5 mx-auto sections-container"
				v-if="search.length > 2"
				key="search">
				<h4 class="text-uppercase">Resultado da pesquisa - {{ search }}</h4>
				<ul class="list-container"
					v-if="articles.length > 0">
					<li v-for="article in articles"
						:key="article.id"
						@click="ClearArticles()">
						<router-link
							v-if="article.category && article.section"
							:to="{ name: 'Article', params: {
								category_slug: article.category.slug,
								section_slug: article.section.slug,
								article_slug: article.slug
							}}">
							{{ article.titulo }}
						</router-link>
					</li>
				</ul>
				<small
					v-if="quantity != null">Quantidade de artigos encontrados: <b>{{ quantity }}</b></small>
			</div>
			<div class="col-12 my-5 d-flex justify-content-center mx-auto"
				v-else-if="search.length < 3 && is_home"
				key="fa">
				<div class="fa-container">
					<h4 class="text-uppercase">Perguntas Frequentes</h4>
					<ul class="list-container">
						<li v-for="section in fa_sections"
							:key="section.id">
							<router-link
								class="col-12"
								:to="{ name: 'Section', params: {
									category_slug: 'perguntas-frequentes',
									section_slug: section.slug
								}}">
								{{ section.descricao }}
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'

    import Categories from './../categories/Categories'

	export default {

		name: 'Index',
        components: {
            Categories
        },
		data () {
			return {
				search:		"",
				articles:	[],
				quantity:	null
			}
		},
		computed: {
			...mapGetters('system', [
				'sections'
			]),
			fa_sections() {
				return this.sections.filter(s => s.faq_category_id === 1)
			},
			is_home() {
				return this.$route.name == 'Home'
			}
		},
		watch: {
			search: {
				handler(val) {
					if(val == 0) {
						this.articles = []
					}
				}
			}
		},
		methods: {
			...mapActions('system', [
				'GetSections'
			]),
			UpdateSearch(val) {
				this.search = val
			},
			UpdateArticles(val) {
				this.articles = val.articles
				this.quantity = val.quantity
			},
			Previous() {
				this.$route.go(-1)
			},
			ClearArticles() {
				this.articles = []
				this.quantity = null
				this.search = ''
			}
		},
		beforeMount() {
			this.GetSections()
		}
	}
</script>

<style lang="css" scoped>
.fa-container {
	width: 100%;
}

@media (min-width: 1200px) {
    .fa-container {
        width: 900px;
    }
}
</style>