var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-large mt-5 px-3"},[(_vm.is_home)?_c('Search',{ref:"search",on:{"search":_vm.UpdateSearch,"update":_vm.UpdateArticles}}):_vm._e(),(!_vm.is_home)?_c('div',{staticClass:"col-md-6 offset-md-3 mb-5 px-0"},[_c('button',{staticClass:"px-5 btn-previous btn btn-light",on:{"click":_vm.Previous}},[_c('i',{staticClass:"fal fa-chevron-left px-2"})])]):_vm._e(),_c('Categories'),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.search.length > 2)?_c('div',{key:"search",staticClass:"col-12 col-md-8 my-5 mx-auto sections-container"},[_c('h4',{staticClass:"text-uppercase"},[_vm._v("Resultado da pesquisa - "+_vm._s(_vm.search))]),(_vm.articles.length > 0)?_c('ul',{staticClass:"list-container"},_vm._l((_vm.articles),function(article){return _c('li',{key:article.id,on:{"click":function($event){return _vm.ClearArticles()}}},[(article.category && article.section)?_c('router-link',{attrs:{"to":{ name: 'Article', params: {
								category_slug: article.category.slug,
								section_slug: article.section.slug,
								article_slug: article.slug
							}}}},[_vm._v(" "+_vm._s(article.titulo)+" ")]):_vm._e()],1)}),0):_vm._e(),(_vm.quantity != null)?_c('small',[_vm._v("Quantidade de artigos encontrados: "),_c('b',[_vm._v(_vm._s(_vm.quantity))])]):_vm._e()]):(_vm.search.length < 3 && _vm.is_home)?_c('div',{key:"fa",staticClass:"col-12 my-5 d-flex justify-content-center mx-auto"},[_c('div',{staticClass:"fa-container"},[_c('h4',{staticClass:"text-uppercase"},[_vm._v("Perguntas Frequentes")]),_c('ul',{staticClass:"list-container"},_vm._l((_vm.fa_sections),function(section){return _c('li',{key:section.id},[_c('router-link',{staticClass:"col-12",attrs:{"to":{ name: 'Section', params: {
									category_slug: 'perguntas-frequentes',
									section_slug: section.slug
								}}}},[_vm._v(" "+_vm._s(section.descricao)+" ")])],1)}),0)])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }