const requests 	= (state) => {
	return state.request_loading
}

const categories = (state) => {
	return state.categories
}

const sections = (state) => {
	return state.sections
}

const menu_options = (state) => {
	return state.menu_options
}

export {
	requests,
	categories,
	sections,
	menu_options
}