<template>
    <div class="container-fluid col-12 articles-container">
        <h2 class="pl-2">{{ selected_section.descricao }}</h2>
        <div class="col-12 content d-flex flex-wrap p-0 mt-4">
            <div v-if="filtered_articles.length == 0" 
                class="col-12 mt-md-5 d-flex justify-content-center">
                <p>Não há artigos nesta seção!</p>
            </div>
            <div class="section-wrapper col-12 col-md-4 p-0">
                <ul class="list-container mt-0">
                    <li class="section-content"
                        v-for="article in filtered_articles"
                        :key="article.id">
                        <router-link class="article-li"
                            :to="{ name: 'Article', params: {
                                article_slug: article.slug
                            }}">
                            {{ article.titulo }}
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-md-8 article-wrapper">
                <Article
                    v-if="selected_article"
                    v-model="selected_article">
                </Article>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import Article from './../../articles/components/Article'

export default {
    name: "Section",
    components: {
        Article
    },
    props:  {
        section_slug: {
            type:       String,
            default:    ''
        },
        props_search: {
            type:       String,
            default:    ''
        }
    },
    data () {
        return {
            all_articles:           [],
            selected_article:   null,
            url_slug:           '',
            search:             ''
        }
    },
    computed: {
        ...mapGetters('system', [
            'categories', 'sections'
        ]),
        selected_section()
        {
            let section = this.sections.filter(s => s.slug == this.section_slug)[0]

            if(section)
            {
                return section
            }

            return {}
        },
        articles()
        {
            return this.all_articles.filter(a => a.faq_section_id == this.selected_section.id)
        },
        is_slug()
        {
            let check = false

            check = this.$route.fullPath.indexOf('#') > -1
            if(check)
            {
                let index = this.$route.fullPath.indexOf('#')

                this.url_slug = this.$route.fullPath.substr(index + 1)
            }

            return check
        },
        filtered_articles()
        {
            let filtered = this.all_articles.filter(a => {
                return a.faq_section_id == this.selected_section.id
            })

            if(filtered)
            {
                this.selected_article = filtered[0]
            }

            if(this.is_slug)
            {
                this.selected_article = filtered.filter(a => {
                    return a.slug == this.url_slug
                })[0]
            }
            
            return filtered
        },
    },
    watch: {
        props_search: {
            handler(val)
            {
                this.search = val
            }
        },
        filtered_articles: {
            handler(val)
            {
                if(!this.is_slug)
                {
                    this.url_slug = val[0].slug
                }
            }
        },
        $route: {
            handler(val)
            {
                if(this.selected_section.descricao)
                {
                    document.title = "IM | FAQ - " + this.selected_section.descricao
                } else {
                    document.title = "IM | FAQ"
                }
            }
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async GetArticles()
        {
            const vm = this

            vm.StartLoading()

            return await window.api.call('get', '/get-faq-items')
                .then(({data}) => {
                    if(data.response)
                    {
                        vm.all_articles = data.articles

                        if(vm.selected_section.descricao)
                        {
                            document.title = "IM | FAQ - " + vm.selected_section.descricao
                        } else {
                            document.title = "IM | FAQ"
                        }

                    }
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        async ChangeArticle(article)
        {
            this.selected_article = article
        },
    },
    beforeMount() {
        this.GetArticles()
    },
}
</script>

<style>

@media (min-width: 1500px) {
    .articles-container {
        max-width: 1600px !important;
    }
}

</style>